.stay-fullDescription {
  max-height: 10000px;
  transition: max-height 1s ease-in-out;
}

.stay-fullDescription.description-hidden {
  max-height: 100px;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
}

.card-description p {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}


